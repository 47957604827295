<template>
    <wait-for-resource :resource="group">
        <router-view :group="group" />
    </wait-for-resource>
</template>

<script>
import {Group} from '@/models/vue-mc/Group';

export default {
    name: 'GroupRouterView',
    data() {
        return {
            group: new Group({id: this.$route.params.id}),
        };
    },
    watch: {
        '$route.params.id': {
            handler: async function(id) {
                this.group = new Group({id});

                await this.group.fetch();
            },
            immediate: true,
        },
    },
};
</script>
